export const pages = [
    //"swim.png?auto=compress&cs=tinysrgb&dpr=1&w=1500",

    //Cover Page
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/wallpaper/mag_co.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/wallpaper/cover_in.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/ads/studio.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    //Contents
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/contents.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    //Editor's Note
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/editor.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
  
    //Colission Course - LV
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/lvgucci_intro.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/lv1.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/gucci1.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/lv2.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/gucci2.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/lv3.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/gucci3.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/lv_gucci.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
   

   
 //Intro article
//NK Sne
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Nk_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/nk_sne.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/sne_pro.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/nk_story.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/wallpaper/vision.png?raw=true=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",

//Glenda Lee
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/article/feature_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/article/Glenda.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/article/glenda_pro.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/article/glenda_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/article/glenda_details.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
//Gallery
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Glenda_runway.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/glenda_deco.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/glenda_fashion.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/glenda_gallery.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/glenda_close.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",

//Ads
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/ads/del_fono_ad.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/ads/Delfono_gall.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
   
//Contents
//"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/contents.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    //Abenathi
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Abenathi_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
"https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Abenathi.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",


     //Buhle
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Busi_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/buhle.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Buhle_gal.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Buhle_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",

   //Bontle 
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Bontle_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Bontle.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Bontle_gal.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Bontle_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    
    //Katlego
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Katlego_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Katleho.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Katlego_gal.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Katli_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    //Learnmore
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Learnmore_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Learnmore.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/learnmore_gall.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Learnmore_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    //Lebohang
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Lebohang_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Lebohang.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Lebo_gall.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Lebo_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    //Lerato
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Lerato_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Lerato.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Lerato_gallery.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Lerato_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
   
    // Matete
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Matete_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Matete.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
   
   //Melrose
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Melrose_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=100",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Kanegelo.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Melrose_Galley.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Merose_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    
    //Nwabisa
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Nwabisa_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=100",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Nwabisa.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500", 
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Nwabisa_gallery.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Nwabisa_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    
    //Oratile
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Oratile_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Oratile.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    
   
   //Phillip
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Phillip_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Phillip.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    
     //Sthembele
     "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Sthe_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
     "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Sthe.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
     "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Sthe_gallery.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
     "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Sthe_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",

      //Tshiamo
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/covers/Tshiamo_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Tshiamo.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Tshiamo_gal.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/gallery/Tshiamo_cert.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",

   //Music cover
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/wallpaper/music_cover.png?raw=true=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
   //Bonolo Coverage
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/article/Bonolo_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/article/bonolo_bio.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/article/bonolo_credits.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",

    
   
    //True Crime Drama
     "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/wallpaper/section.png?raw=true=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Acrimony_intro.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Acrimony_art.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/fashionrunway/modellers/Acrimony_closure.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
    
    
   //Politics
   //Trump and Economic war
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/articles/economic_wars/Trump1.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/articles/economic_wars/Trump2.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",
   "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/articles/economic_wars/Trump3.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1506",

    //King Davon
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/disclaimer.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/king_devon.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/storymode/devon_closure.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",

    //Wildilife
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/wildlife/Anting_cover.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/wildlife/Anting_desc.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
    "https://github.com/TommyTeaVee/ImpiloV2/blob/2025-digital/src/content/wildlife/Anting_close.png?raw=true?auto=compress&cs=tinysrgb&dpr=1&w=1500",
  ];
